var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{staticClass:"artcle-header"},[_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","lg12":""}},[(_vm.$vuetify.breakpoint.smAndDown)?_c('v-card',{attrs:{"flat":""}},[_c('v-img',{staticClass:"banner-img ma-0",attrs:{"eager":"","height":'200px',"gradient":"to bottom right, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.4) ","lazy-src":require('@/assets/Wikipedia-logo-version-2.svg?lazy'),"src":_vm.bannerImageUrl ||
            require('@/assets/Wikipedia-logo-version-2.svg?lazy'),"position":"center 33%"}},[_c('v-card-title',{staticClass:"white--text ma-0"},[_c('h1',{staticClass:"white--text"},[_vm._v(" "+_vm._s(_vm.article.title)+" ")])]),_c('v-card-text',{staticClass:"ma-0 white--text"},[_c('h3',{staticClass:" white--text font-weight-regular"},[_vm._v(" "+_vm._s(_vm.description)+" ")])])],1),_c('v-card-actions',{staticClass:"mx-0 pa-0 hidden-sm-and-down"})],1):_vm._e(),(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-layout',{attrs:{"fill-height":""}},[_c('v-flex',{attrs:{"md3":"","lg3":""}},[_c('v-sheet',{staticClass:"pt-5",attrs:{"min-width":"100%","min-height":"100%"}},[(_vm.bannerImageUrl)?_c('v-img',{staticClass:"banner-img ma-0",attrs:{"eager":"","height":'150px',"width":"100%","src":_vm.bannerImageUrl,"contain":"","position":"center right"}}):_vm._e()],1)],1),_c('v-flex',{attrs:{"md9":"","lg9":""}},[_c('v-card',{staticClass:"px-3 ma-0",attrs:{"flat":"","height":"100%"}},[_c('v-card-title',{staticClass:"ma-0"},[_c('h1',[_vm._v(" "+_vm._s(_vm.article.title)+" ")])]),_c('v-card-text',{staticClass:"ma-0"},[_c('h3',{staticClass:"font-weight-regular"},[_vm._v(" "+_vm._s(_vm.description)+" ")])]),_c('v-card-text',{staticClass:"ma-0 py-0 align-end"},[(_vm.lastmodifier)?_c('h3',{staticClass:"text-body-2 font-weight-regular",domProps:{"innerHTML":_vm._s(
                _vm.$i18n(
                  'article-lastmodified',
                  _vm.lastmodifier.user,
                  _vm.lastmodifiedRelative,
                  _vm.lastmodified
                )
              )}}):_vm._e()])],1)],1)],1):_vm._e()],1),_c('v-layout',[_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","lg12":""}},[_c('v-sheet',[_c('v-toolbar',{attrs:{"flat":"","dense":"","color":"transparent"}},[_c('div',{staticClass:"flex-grow-1"}),_c('v-toolbar-items',[(_vm.quickfacts)?_c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition","attach":"article"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","text":""}},on),[_c('v-icon',[_vm._v(_vm._s(_vm.mdiLightbulbOnOutline))])],1)]}}],null,false,2152422686),model:{value:(_vm.quickfactsDialog),callback:function ($$v) {_vm.quickfactsDialog=$$v},expression:"quickfactsDialog"}},[_c('v-card',[_c('v-toolbar',[_c('v-toolbar-title',[_vm._v("Quick facts")]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.quickfactsDialog = false}}},[_c('v-icon',[_vm._v(_vm._s(_vm.mdiClose))])],1)],1)],1),_c('v-card-text',{domProps:{"innerHTML":_vm._s(_vm.quickfacts.infobox)}})],1)],1):_vm._e(),_c('v-btn',{attrs:{"small":"","text":""}},[_c('v-icon',[_vm._v(_vm._s(_vm.mdiPencilOutline))])],1),_c('article-revisions',{attrs:{"article":_vm.article}}),_c('v-btn',{attrs:{"small":"","text":""}},[_c('v-icon',[_vm._v(_vm._s(_vm.mdiForumOutline))])],1),_c('article-languages',{attrs:{"article":_vm.article}}),(_vm.article.geo)?_c('v-btn',{staticClass:"font-weight-regular",attrs:{"small":"","text":"","href":_vm.locationLink(_vm.article),"target":"__blank"}},[_c('v-icon',[_vm._v(_vm._s(_vm.mdiMapMarkerOutline))])],1):_vm._e(),(_vm.article.wikidataId)?_c('v-btn',{staticClass:"font-weight-regular",attrs:{"small":"","text":"","href":("https://www.wikidata.org/wiki/" + (_vm.article.wikidataId)),"target":"__blank"}},[_c('v-img',{attrs:{"src":require("../assets/Wikidata-logo.svg"),"max-width":"24"}})],1):_vm._e(),(_vm.article.pronunciation)?_c('audio',{attrs:{"controls":"","src":_vm.article.pronunciation.url}}):_vm._e()],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }